module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"335477081616253"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["br","cl","co"],"defaultLanguage":"br","trailingSlash":"always","siteUrl":"https://homolog.lab.asics.com","generateDefaultLanguagePage":true,"redirect":true,"i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/foot-id/(.*)?","excludeLanguages":["cl","co"]},{"matchPath":"/:lang?/cps-tennis/(.*)?","excludeLanguages":["cl","co"]},{"matchPath":"/:lang?/gel-cumulus24/(.*)?","excludeLanguages":["cl","co"]},{"matchPath":"/:lang?/gel-kinsei/(.*)?","excludeLanguages":["cl","co"]},{"matchPath":"/:lang?/gel-nimbus23/(.*)?","excludeLanguages":["cl","co"]},{"matchPath":"/:lang?/gel-nimbus24/(.*)?","excludeLanguages":["cl","co"]},{"matchPath":"/:lang?/metaspeed/(.*)?","excludeLanguages":["cl","co"]},{"matchPath":"/:lang?/novablast-2/(.*)?","excludeLanguages":["cl","co"]},{"matchPath":"/:lang?/cumulus-23/(.*)?","excludeLanguages":["cl","co"]},{"matchPath":"/:lang?/ekiden/(.*)?","excludeLanguages":["cl","co"]},{"matchPath":"/:lang?/sustainability/(.*)?","excludeLanguages":["cl","co","br"]},{"matchPath":"/:lang?/lojas/(.*)?","excludeLanguages":["cl","co"]},{"matchPath":"/:lang?/totem/(.*)?","excludeLanguages":["cl","co"]},{"matchPath":"/:lang?/dia-das-maes/(.*)?","excludeLanguages":["cl","co"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
